@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: var(--denim);
  --secondary: white;
  --main-text-color: var(--shark);
  --list-title-color: var(--denim);
  --list-company-color: var(--shark);
  --list-loc-color: var(--jumbo);
  --visited-color: var(--purple-heart);

  /* profile */
  --main-border-color: var(--mischka);
  --page-text-color: var(--shark);
  --page-text-color2: var(--jumbo);
  --main-border-radius: 0px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
}
body {
  font-family: 'DM Mono', monospace;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  min-height: 100vh;
  background: #fff;
  color: var(--main-text-color);
}
.container {
  max-width: 992px !important;
  margin: auto;
}
.hidden {
  display: none;
}

.smooth-image {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%),
    url('/bg.webp') no-repeat center / cover;
}

.pure-shadow {
  box-shadow: 0.5rem 0.5rem var(--gray-900);
}

.outline-text {
  -webkit-text-stroke: 1px var(--gray-900);
}

.digit-shadow {
  text-shadow: 14px 8px 4px rgba(0, 0, 0, 0.3);
}

.custom-shadow-kg {
  box-shadow: 4px 4px 0px 0px black;
}

.homepage-background {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('/kg/homepage-backgroud1.png') no-repeat center / cover;
}

.homepage-text-shadow {
  text-shadow: 6px 6px 0px #000000;
}

.homepage-shadow {
  box-shadow: 6px 6px 0px 0px black;
}
.cell-background {
  background-image: repeating-linear-gradient(0deg, transparent 0 79px, var(--athens-gray) 0 80px),
    repeating-linear-gradient(90deg, transparent 0 79px, var(--athens-gray) 0 80px);
}

.homepage-collapsers {
  max-width: 730px;
  width: min(100%, 730px);
}

.popup-bg-blur-kg {
  backdrop-filter: blur(5.699999809265137px);
  background: rgba(0, 0, 0, 0.64);
}
